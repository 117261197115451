@charset "UTF-8";
@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600
}

@font-face {
  font-family: 'Archivo';
  src: url('../fonts/Archivo-SemiBoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600
}
