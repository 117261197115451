@charset "UTF-8";
.galeries {
  position: relative;
  transition: transs(height max-height background-color opacity);
  opacity: 0;

  &.initialized {
    opacity: 1;
  }

  .galeries-wrapper {

    .swiper-container {
      transition: trans(height max-height);
      margin-bottom: 2px;

      .swiper-slide {
        width: px(300);
        position: relative;
        background-size: cover;
        background-position: center center;

        .sizer {
          position: relative;
          height: 0;
          padding-bottom: 75%;
        }



        &.unsetwidth {
          width: 100px;
          opacity: 0;
          transition: trans(opacity, 0)
        }
        transition: trans(opacity);
        cursor: pointer;

        img {
          max-height: 100%;
          min-height: 100%;
        }

        .text {
          position: absolute;
          @include flexBoxes(flex-start, center);
          top:0;right:0;bottom:0;left:0;
          background-color: rgba(0,0,0,0.6);
          padding: px(20);
          opacity: 0;
          transition: trans(opacity);
          p {
            color: $white
          }
        }

        &:hover {
          .text {
            opacity: 1;
          }
        }
      }
    }

    .slider-btn {
      opacity: 0;
    }

    .swiper-container:hover .slider-btn {
      opacity: 1;
    }
  }

  //&.zoom-in {
  //  height: 100vh;
  //  max-height: 100vh;
  //  background-color: $blue;
  //
  //  .galeries-wrapper {
  //
  //    .swiper-container {
  //      opacity: 0;
  //      height: 5vh;
  //      max-height: 5vh;
  //    }
  //
  //    .swiper-container.active {
  //      opacity:1;
  //      height: 90vh;
  //      max-height: 90vh;
  //    }
  //  }
  //}

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    //width: 100%;
    //height: 100%;
    transform: translateX(-200%);
    max-height: 0;
    height: 0;
    max-width: 0;
    width: 0;
    opacity: 0;
    overflow: hidden;
    background-color: $navBlack;
    z-index: 11;
    transition: transs(opacity transform);

    .swiper-slide {
      height: 100%;
      width: 100%;
      background: none !important;
    }

    .slider-btn {
      width: 33%;
      svg {
        width: px(40);
      }

      &.slider-next {
        text-align: right;
        @include mlView() {
          padding-right: px(35);
        }
        @include sView() {
          padding-right: px(20);
        }
      }

      &.slider-prev {
        @include mlView() {
          padding-left: px(35);
        }
        @include sView() {
          padding-left: px(20);
        }
      }
    }

    button.close {
      position: absolute;
      display: block;
      top: px(20);
      z-index: 1;
      @include mlView() {
        right: px(35);
        width: px(48);
        height: px(48);
      }

      @include sView() {
        right: px(20);
        width: px(33);
        height: px(33);
      }

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  &.overlay-visible {
    height: 100vh;

    .overlay {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      transform: translateX(0);
      //transform: translateX(-50%) translateY(-50%);
      opacity: 1;

      .swiper-slide {
        position: relative;
        max-height: 100vh;
        height: 100vh;
        @include flexBoxes(center, center);
        img {
          display: block;
          max-height: 100%;
          max-width: 100%;
        }
      }

      .text {
        display: none;
      }
    }
  }
}
