@charset "UTF-8";

$content-width: 829;
$sidePadding: 20;
$headerHeight: 120;
$headerHeight-small: 67;
$footerHeight: 205;
$cnwidth: 700;
$smallHeightCNwidth: $cnwidth - 150;
$stdSpeed: 200;
$stdBCurve: cubic-bezier(.27,.72,.21,.91);
$footerLogoLargeWidth: 250;

//font
$font-family: 'Archivo', sans-serif;
$textSize: 17;
$titleSize: 23;

//screen widths breakpoints
$screen: (
  "small": 749,
  "medium": 1024,
  "large": 1440
);
$screenHeightBreakPoint: 872;



//color
$white: #fff;
$black: #000;
$blue:  #4977AA;
$lightBlue: #8DB2D4;
$grey:  #858788;
$usbRed: #EA3423;
$navBlack: #0B0E11;
$violet: #5F4262;

