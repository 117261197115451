@charset "UTF-8";


#main-wrapper {
  min-height: calc(100vh - #{px($headerHeight + $footerHeight)});
  header {
    .section {
      margin-bottom: 0;
    }

    .breadcrumbs {
      margin-bottom: px(7);
      a {
        text-decoration: none;
      }

      @include sView() {
        margin-top: px(15);
        margin-bottom: px(15);
      }
    }
  }
}
