@charset "UTF-8";

.video-component {
  .video-wrapper {
    position: relative;

    .sizer {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;

    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
