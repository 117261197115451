@charset "UTF-8";

#cookie_alert {
  background-color: $navBlack;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;

  .content-wrapper{
    @include flexBoxes(flex-start, center, row, nowrap);
    padding: px(15) px($sidePadding);
    .text-info{
      width: 85%;
      padding-right: px(15);
      color: $white;

      *:last-child {
        margin-bottom: 0;
      }
    }
    .consent-btn-wrapper {
      .consent-btn {
        background-color:$violet;
        padding: 8px 10px;
        color: $white;
      }
    }
  }

  &.accepted {
    display: none;
  }
}
