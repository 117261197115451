@charset "UTF-8";

#search {
  .results {
    margin-bottom: px(80);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .entry {
    a {
      text-decoration: none;
    }
  }
}
