@charset "UTF-8";
.form {

  .form-wrapper {
    dfn {
      display: none;
    }

    .field {
      display: none;
      opacity: 0;
      visibility: hidden;
    }

    .form-row {
      margin-bottom: px(15)
    }

    label.flex {
      cursor: pointer;
      span {
        width: px(100) ;
      }

      input {
        display: block;
        font-size: px($textSize);
        height: px(32);
        padding: px(5);
        background-color: rgba(100, 65, 100, 0.3);
        width: 100%;

        &[type=file] {
          padding: px(3);
        }
      }

      textarea {
        display: block;
        border: none;
        height: px(100);
        width: 100%;
        outline: none;
        font-size: px($textSize);
        padding: px(5);
        background-color: rgba(100, 65, 100, 0.3);

        &:focus {
          outline: none;
        }
      }
    }

    input[type=submit], button {
      background-color: $violet;
      padding: px(5) px(50);
      color: $white;
      transition: trans(opacity);

      &[disabled] {
        opacity: 0.5;
        cursor: default;
      }
    }

    .submit-block {
      @include flexBoxes(space-between, center, row, nowrap);

      .status {
        height: px(50);
        width: px(122);
      }
    }
  }

  .file-wrapper {
    position: relative;
    cursor: pointer;

    input {
      opacity: 0;
    }

    .file-btn {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 0;
      text-align: left;
      line-height: px(32);
      background-color: rgba(100, 65, 100, 0.3);
      transition: trans(background-color);
      span {
        vertical-align: middle;
        line-height: 1.2em;
        transition: trans(color);
      }

      .add {
        display: inline-block;
        height: 100%;
        width: px(33);
        padding-left: px(5);
        padding-top: px(2);

        svg {
          height: px(25);
          width: px(25);
        }
      }

      .label {
        margin-left: px(10);
      }

      .remove {
        display: none;
      }
    }

    &.file {
      .file-btn {
        background-color: $violet;

        span {
          color: $white;
        }

        .remove {
          position: absolute;
          display: block;
          right: px(5);
          top: 0;
          height: px(32);
          width: px(32);
          padding: px(5);
        }
      }
    }
  }
}
