@function px($value) {
  @if ($value == null) {
    @return 0;
  } @else {
    @return #{$value + "px"};
  }
}

@function rem($px) {
  @if ($px == null) {
    @return 0;
  } @else {
    @return #{($px / $base-font-size) + "rem"};
  }
}

@function pc($pc) {
  @if ($pc == null) {
    @return 0;
  } @else {
    @return #{ $pc + "%"};
  }
}

@function ms($value) {
  @if ($value == null) {
    @return 0;
  } @else {
    @return #{$value + "ms"};
  }
}

@function vw($value) {
  @if ($value == null) {
    @return 0;
  } @else {
    @return #{$value + "vw"};
  }
}

@function vh($value) {
  @if ($value == null) {
    @return 0;
  } @else {
    @return #{$value + "vh"};
  }
}


@function vwRel($px) {
  @if ($px == null) {
    @return 0;
  } @else {
    @return #{(round($px / $content-width * 10000) / 100) + "vw"};
  }
}

@function vwRelSmall($px) {
  @if ($px == null) {
    @return 0;
  } @else {
    @return #{(round($px / 375 * 10000) / 100) + "vw"};
  }
}

@function vhRel($px, $base:872) {
  @if ($px == null) {
    @return 0;
  } @else {
    @return #{(round($px / $base * 10000) / 100) + "vh"};
  }
}

@function trans($propertyName, $speed:$stdSpeed, $ease:$stdBCurve) {
  @return  $propertyName ms($speed) $ease;
}

@function transs($propertyNames, $speed:$stdSpeed, $ease:$stdBCurve) {
  $trsString:'';
  $notFirst: false;
  @each $propName in $propertyNames {
    @if $notFirst {
      $trsString: $trsString + ', ';
    }
    $trsString: $trsString + trans($propName, $speed, $ease);
    $notFirst: true;
  }
  @return #{$trsString};
}
