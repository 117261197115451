@charset "UTF-8";

.project-list {

  .project-entry {
    position: relative;
    margin-bottom: px(25);

    &:last-child {
      .project-wrapper {
        border-bottom: none;
      }
    }

    &:hover {
      .text-wrapper:before, .images:before {
        opacity: 1;
      }
    }
  }

  .text-wrapper, .images {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: px(-5);
      right: px(-5);
      left: px(-5);
      bottom: px(-5);
      background-color: #ececec;
      opacity: 0;
      transition: trans(opacity);
      z-index: -1;
    }
  }

  .project-wrapper {
    header {
      @include mlView() {
        min-height: px(60);
        @include flexBoxes(space-between, flex-start, row, nowrap);

        .left {
          width: 60%;
        }

        .right {
          max-width: 40%;
        }
      }

      h3 {
        margin-bottom: 0;
      }

      .right {

        span {
          display: inline-block;
          height: px(30);

          &.status {
            margin-right: px(10);
            width: px(75);
          }

          &.btn {
            width: px(30);
          }

          svg {
            height: 100%;
            width: auto;
          }
        }

        @include sView() {
          text-align: right;
          margin-bottom: px(20);
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .images-wrapper {
    @include flexBoxes(center, stretch, row, nowrap);
    @include mlView() {
      height: vwRel(178);
      max-height: px(178);
    }

    @include sView() {
      height: vwRelSmall(120);
      max-height: px(120);
    }

    .image {
      height: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.images-1 {
      .image {
        width: 100%;
      }
    }

    &.images-2 {
      .image {
        &:first-child {
          width: 77%;
        }

        &:last-child {
          width: 33%;
        }
      }
    }

    &.images-3 {
      .image {
        width: 27%;

        &:first-child {
          width: 37%;
        }

        &:last-child {
          width: 37%;
        }
      }
    }
  }

  .images + .text {
    padding-top: px(15);
  }


}
