@charset "UTF-8";

.mgnlLikeBtn {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 15px;
  display: inline-block;
  font-size: 10px;
  cursor: pointer;
  background-color: #03e1ff;
  color: black;
  padding: 2px 5px 2px 5px;
  font-family: Verdana, sans-serif !important;
  z-index: 9999999;
  text-transform: uppercase;

    @include sView() {
      display: none;
    }
}

body.fixed {
  max-height: 100vh;
  overflow: hidden;
}

body {
  min-height: 100vh;
  @include mlView() {
    padding-top: px($headerHeight);
  }

  @include sView() {
    padding-top: px($headerHeight-small);
  }
  background-color: $white;
  box-sizing: border-box;
  font-family: $font-family;
  font-size: px($textSize);
  line-height: 1.2;
  min-width: px(320);
  color: $violet;
  * {
    box-sizing: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  a, a:visited {
    color: inherit;
  }
}

h1 {
  font-weight: bold;
  font-size: px($titleSize);
  margin-bottom: px($titleSize);
}

h3, .subtitle {
  font-weight: bold;
  font-size: px($textSize);
  margin-bottom: px($titleSize);
}

p {
  margin-bottom: px($titleSize * 0.8);
}

strong {
  font-weight: bold;
}

.content-width {
  max-width: 100%;
  width: px($content-width + 2 * $sidePadding);
  margin: 0 auto;
  padding-left: px($sidePadding);
  padding-right: px($sidePadding);
}

.text-width {
  width: px(0.8 * $content-width);
  max-width: 100%;
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $grey;
}

input {
  border:none;
}

button, .button, input {
  outline:  none;
  &:focus {
    outline: none;
  }
}

.line-before {
  border-top: 1px solid $grey;
  padding-top: px(25);
  margin-top: -1px;
}

.line-after {
  border-bottom: 1px solid $grey;
  padding-bottom: px(25);

  .text *:last-child {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
}

.swiper-container {
  .slider-btn {
    position: absolute;
    height: 100%;
    width: px(70);
    top: 0;
    z-index: 1;
    cursor: pointer;
    outline: none;

    &:focus {
      outline: none;
    }

    &.slider-prev {
      left: 0;
      padding-left: px(10);
      padding-right: px(20)
    }

    &.slider-next {
      right: 0;
      padding-right: px(10);
      padding-left: px(20);
    }

    svg {
      height: 100%;
      width: 100%;
    }

    transition: trans(opacity);

    &.swiper-button-disabled {
      opacity: 0 !important;
    }
  }
}

.background-image {
  position: fixed;
  z-index: -1;
  bottom: vh(10);
  height: vhRel(500);
  width: vhRel(0.905 * 500);
  img {
    width: 100%;
    height: 100%;
  }

  @include sView() {
    display: none;
  }
}

.social-platform-links {
  @include flexBoxes(flex-start, center, row, nowrap);

  li a {
    position: relative;
    display: block;
    height: px(22);
    width: px(22);

    svg {
      max-width: 100%;
      max-height: 100%;
    }

  }
  li:not(:last-child) {
    margin-right: px(10)
  }
}

em {
  font-style: italic;
}

.text {
  ol {
    padding-left: px(20);
    margin-bottom: 1em;
    li {
      list-style: decimal
    }
  }

  ul {
    padding-left: px(20);
    margin-bottom: 1em;
    li {
      list-style: disc;
    }
  }
}

.status {

  svg {
    height: 100%;
    width: 100%;
  }
  path {
    fill: #dfecf4;
    transition: trans(fill);
  }

  &.status-1 {
    .red path {
      fill: #ec1f31;
    }
  }

  &.status-2 {
    .orange path {
      fill: #f39222;
    }
  }

  &.status-3 {
    .green path {
      fill: #75c045;
    }
  }
}

.nowrap {
  white-space: nowrap;
  word-break: keep-all;
}

.text {
  a {
    position: relative;
    text-decoration: none;
    transition: transs(color background-size margin padding);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;

    &:not(.nohover):hover, &:focus{
      color: $black;
      text-decoration: none;
      background-size: 100% 100%;
      margin: -2px;
      padding: 2px;
    }

    &.nohover {
      background: unset!important;
    }
  }


}
