@charset "UTF-8";

//sections theme
.theme-bg {
  transition: trans(background-color);
}
.theme-color {
  transition: trans(color);
}
.theme-fill {
  transition: trans(fill);
}
.theme-stroke {
  transition: trans(stroke);
}


//general colors
.fill {
  transition: trans(fill);

  &.fill-red {
    fill: $usbRed;
  }

  &.fill-violet {
    fill: $violet;
  }
}

#home, #page-header.nav-open {
  .fill {
    fill: $white;
  }
}

#main-wrapper {
  color: $violet
}

#page-header {
  color: $white;
}
