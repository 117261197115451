@charset "UTF-8";

.textImage {

   &.horizontal {
     .wrapper {
       @include mlView() {
         @include flexBoxes(flex-start, flex-start, row, wrap);
         .text-wrapper, .image-wrapper {
           width: 50%;

           &:first-child {
             padding-right: px($sidePadding / 2);
           }

           &:last-child {
             padding-left: px($sidePadding / 2);
           }

         }
       }
     }
   }

  .image-wrapper {
    img {
      max-height: px(750);
    }
  }
}


