@charset "UTF-8";

.panorama-wrapper {
  position: relative;
  height: vw(33);
  max-height: vw(33);
  margin-bottom: px(25);

  @include sView() {
    height: vw(70);
    max-height: vh(50);
  }

  #pano {
    max-height: 100%;
  }

}
