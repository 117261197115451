@charset "UTF-8";





body:not(#home) {
  .footer-logo {
    width: px(100);
    height: px(0.76 * 100);
  }

}

.footer-logo {
  svg {
    width: 100%;
    height: 100%;
  }
}

#footer {
  margin-top: px(40);

  .text {
    * {
      font-size: px(15);
    }
  }

  .footer-wrapper {
    position: relative;
    padding-top: px(5);
    padding-bottom: px(30);
    @include maxView(560) {
      padding-bottom: px(80);
    }

    @include flexBoxes(space-beteen, flex-end, row, nowrap);

    .text {
      min-height: px(85);
      width: calc(100% - 100px);
      padding-right: px(10);
      @include flexBoxes(flex-start, flex-end, row, wrap);


      p {
        margin-bottom: 0;
      }

      .social-platform-links {
        margin-bottom: 0;

        @include sView() {
          width: 100%;
          padding-left: 0;
          padding-top: px(4);
        }

        li {
          list-style: none;
        }
      }
    }
  }
}
