@charset "UTF-8";

.page-links {
  .wrapper {
    @include flexBoxes(flex-start, flex-start, row, wrap);
  }

  .entry {
    width: 33%;
    min-width: px(205);
    margin-bottom: px($sidePadding);
    a {
      @include flexBoxes(flex-start, center, row, nowrap);
      text-decoration: none;
      padding-right: px(10);

      .disc {
        display: block;
        width: px(45);
        min-width: px(45);
        height: px(45);
        min-height: px(45);
        margin-right: px(7);
        background-size: cover;
        background-position: center center;
        background-color: $violet;
        border-radius: 50%;
      }

      .link-title {
        font-size: px($textSize);
      }
    }


  }
}
