@charset "UTF-8";
body:not(#home) {
  @include maxView(1435) {
    #page-header {
      background-color: $white;
    }

    .header-wrapper {
      padding-bottom: px(15);
    }
  }
}

body.editmode #page-header {
  z-index: 99999;
}

#page-header {
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  #logo, .nav-btn {
    pointer-events: auto;
  }

  &.nav-open {
    pointer-events: auto;
    .fill-red, .fill-black {
      fill: $white;
    }

    .header-wrapper {
      background-color: $navBlack;
      @include mlView() {
        transition-delay: ms($stdSpeed);
      }

      #logo, .nav-btn {
        pointer-events: auto;
      }

      .nav-btn {

        .disappear {
          opacity: 0;
        }

        .rotate-left {
          transform: rotateZ(-45deg);
        }

        .rotate-right {
          transform: rotateZ(45deg);
        }
      }
    }

    #main-nav-container {
      transform: translateY(100vh);
    }
  }
}

.header-wrapper {
  position: relative;
  @include flexBoxes(space-between, flex-start, row, nowrap);
  width: 100%;
  transition: trans(background-color, 0);

  @include mlView() {
    padding: px(35) px(35) px(35) px($sidePadding);
  }

  @include sView() {
    padding:px(15) px(20);
    max-height: px(68);
  }

  .logo-container  {
    @include mlView() {
      svg {
        height: px(45);
        width: px(5.81 * 45)
      }
    }

    @include sView() {
      svg {
        height: px(33);
        width: px(5.81 * 33);
      }
    }
  }

  .nav-btn {

    @include mlView() {
      width: px(48);
      height: px(48);
      line-height: px(48);
    }

    @include sView() {
      width: px(36);
      height: px(36);
      line-height: px(36);

    }

    svg {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      rect {
        transition: transs(transform opacity);
      }

      .rotate-right {
        transform-origin: 50% 50%;
        transform: rotateZ(0);
      }

      .rotate-left {
        transform-origin: 50% 50%;
        transform: rotateZ(0);
      }
    }
  }
}

#main-nav-container {
  position: fixed;
  top: -100vh;
  left: 0;
  width: calc(100% + 20px);
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: $navBlack;
  padding-top: px($headerHeight);
  transition: trans(transform);

  .main-nav-wrapper {
    max-height: 100%;
    overflow: auto;
    padding-left: px($sidePadding);
    padding-right: px($sidePadding + 20);
    max-width: 100%;
    margin: 0 auto;

    .main-nav-scroll {
      padding-bottom: px(50);
      width: px($content-width + 2 * $sidePadding);
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .title {
    position: relative;
    padding-bottom: px(20);
    h2.subtitle {
    font-size: px($titleSize);
    }

    .bg-anim-btn {
      text-align: right;
      line-height: px(18);

      span {
        font-size: px(12);
      }

      #stop_waves {
        position: relative;
        vertical-align: middle;
        margin-left: px(10);
        width: px(36);
        height: px(18);
        border-radius: px(20);
        &.inactive {
          background-color: $grey;

          &:before {
            transform: translateX(-18px);
          }
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          right: px(2);
          bottom: px(2);
          width: px(14);
          height: px(14);
          background-color: $white;
          border-radius: 50%;
          transition: transs(transform background-color);
        }
      }
    }
  }
}

.searchbox {
  padding: px(50) 0;

  label {
    position: relative;
    display: block;

    input#searchInput {
      background-color: $lightBlue;
      width: 100%;
      padding-left: px(105);
      height: px(50);
      font-size: px($textSize);
    }

    span {
      display: block;
      position: absolute;
      left: px(10);
      top: 50%;
      color: #3E6189;
      transform: translateY(-50%);
    }
  }
}

#main-nav {
  li.active > a, li.open > a {
    text-decoration: underline;
  }

  a {
    display: block;
    text-decoration: none;
    &:hover, &:focus{
      text-decoration: underline;
    }
  }

  > ul.nav {
    @include flexBoxes(center, stretch, row, wrap);
    padding-top: px(15);
    width: calc(100% + 20px);
    position: relative;
    left: px(-20);

    > li {
      width: 25%;
      padding-top: px(20);
      padding-left: px(20);
      padding-bottom: px(40);

      > a {
        transition: trans(color);
        //color: $white;
      }

      @include sView() {
        width: 50%;
        min-width: px(200);
      }

      @include mlView() {
        li {
          padding-right: px(15);
        }

        &:not(:first-child) {
          border-left: 1px solid $grey;
        }
      }

      @include maxView(399) {
        width: 100%;
        ul a {
          font-size: px($textSize + 2);
        }
      }

      > ul {
        padding-top: px(5);
        a {
          padding: px(5) px(0);


        }
      }
    }
  }
}

.nav-text {
  margin-top: px(40);

  .text {
    * {
      font-size: px(15);
    }
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $white;
      }
    }
  }
}
