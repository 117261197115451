@charset "UTF-8";

#project {
  .project-header {
    margin-bottom: px(25);

    .swiper-container {
      .swiper-slide {
        @include mlView() {
          height: vw(37);
        }

        @include sView() {
          height: vw(70);
          max-height: vh(50);
        }

         img {
           position: absolute;
           width: 100%;
           height: auto;
           top: 50%;
           left: 50%;
           transform: translateX(-50%) translateY(-50%);
         }
      }
    }
  }

  header {
    position: relative;
    padding-bottom: px($sidePadding);

    @include sView() {
      padding-top: px(55)
    }

    h1 {
      @include mlView() {
        max-width: calc(100% - 200px)
      }
    }

    .status {
      position: absolute;
      top: 0;
      right: px(20);
      width: px(75);
    }
  }
}

.status.project-status {
  position: relative;

  svg {
    height: px(30);
    width: auto;
  }

  .status-text {
    display: block;
    position: absolute;
    font-size: px(11.3);
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    text-align: right;
    white-space: nowrap;
  }
}
