@charset "UTF-8";

.person {
  min-height: px(73);

  .has-image {
    min-height: px(160);
  }

  .image-text {

    .image {
      position: relative;
      border-radius: 50%;
      overflow: hidden;
      max-width: px(180);
      max-height: px(180);

      .sizer {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        background-position: center center;
        background-size: cover;
      }

      img {
        position:absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        max-width: unset;
        max-height: 100%;
      }
    }

    .text-wrapper {
      position: relative;

      button.collapsible-btn {
        position: absolute;
        display: block;
        height: px(30);
        width: px(30);
        top: 0;
        right: px(15);

        &.open {
          .vertical {
            opacity: 0;
          }
        }
      }

      h2.subtitle {
        margin-bottom: 0;
      }
    }

    @include maxView(499) {
      .image-wrapper {
        margin-bottom: px(30);

        .image {
          margin: 0 auto;
        }
      }
    }

    @include minView(500) {
      @include flexBoxes(flex-start, flex-start, row, nowrap);

      .image-wrapper {
        width: px(130);
        padding-right: px($sidePadding);
      }

      .text-wrapper {
        width: calc(100% - 131px);
        padding-left: px($sidePadding);
      }
    }
  }

  .description {
    padding-top: px($textSize);
    @include mlView() {
      padding-left: px(130 + $sidePadding)
    }
  }
}
