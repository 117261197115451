@charset "UTF-8";
.collapsible-wrapper {

  button.collapsible-btn {
    position: relative;
    width: px(30);
    height: px(30);

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: px(30);
      height: px(30);
    }

    .open {
      transition: transs(opacity transform)
    }

    .close {
      opacity: 0;
      transition: trans(opacity);
    }
  }

  &.area-visible {
    button {
      .open {
        transform: rotateZ(90deg);
        opacity: 0;
      }

      .close {
        opacity: 1;
      }
    }

    .collapsible-area {
      transition-delay: ms(100);
      transform: scale(1);
      opacity: 1
    }

    .collapsible.partially-visible {
      &:before {
        height: 0;
        max-height: 0;
      }
    }
  }

  .collapsible {
    overflow: hidden;
    position: relative;
    max-height: 0;
    height: 0;
    transition: transs(height max-height);
  }

  .collapsible-area {
    position: absolute;
    transform: scale(0.9);
    opacity: 0;
    transition: transs(transform opacity);
    padding-bottom: px(20);
  }

  .collapsible-btn {
    cursor: pointer;
  }
}

